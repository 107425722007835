<template>
  <div>
    <SweepForm
      :sweepObj="sweep"
      @cancel="cancelEdit"
      :backRoute="'creative brief'"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SweepForm from './SweepForm';

export default {
  components: {
    SweepForm,
  },
  data() {
    return {
      sweep: { },
    };
  },
  methods: {
    ...mapActions('sweep', ['getSweep']),
    ...mapActions('profile', ['getCreativeOptions']),
    cancelEdit() {
      this.$router.push(`/project_overview/${this.$route.params.projectId}`);
    },
  },
  async mounted() {
    this.getCreativeOptions();
    this.sweep = await this.getSweep(this.$route.params.sweepId);
  },
};
</script>
